import { _, locale } from 'svelte-i18n';

const readTranslatedKey = (key, valuesObj) => {
	let translated;
	locale.subscribe((lang) => {
		if (lang) {
			_.subscribe((val) => {
				translated = val(key, valuesObj);
			});
		}
	});
	return translated;
};

export { readTranslatedKey };
