import { API_BASE, CLIENT_REQUEST_TIMEOUT_MS } from '$src/constants.js';
import { countdown, notification } from '$src/stores.js';
import { handleErrorJson } from './errors';
import { readTranslatedKey } from './i18n';

const timeoutPromise = (promise) => {
	return new Promise((resolve, reject) => {
		const timeoutId = setTimeout(() => {
			reject(new Error(408));
		}, CLIENT_REQUEST_TIMEOUT_MS);
		promise.then(
			(res) => {
				clearTimeout(timeoutId);
				resolve(res);
			},
			(err) => {
				clearTimeout(timeoutId);
				reject(err);
			}
		);
	});
};

// deprecate - only being used in mastodon app
const fetchWithTimeout = async (resource, options = {}) => {
	const { timeout = 8000 } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	try {
		const response = await fetch(resource, {
			...options,
			signal: controller.signal
		});
		clearTimeout(id);
		return response;
	} catch (err) {
		if (err?.name === 'AbortError') {
			console.info(`Request for ${resource} timed out (${timeout}ms)`);
		}
		throw err;
	}
};

// deprecate - only being used in mastodon app
const fetchWithRetry = async (resource, options = {}) => {
	const { totalRetries = 1, retryOnStatusCode = 401 } = options;
	let retries = 0;
	while (retries <= totalRetries) {
		const res = await fetch(resource, options);
		if (!res.ok) {
			if (retries === totalRetries) return res;
			if (res.status === retryOnStatusCode) {
				console.info(`Retrying request to ${resource}`);
				retries++;
				continue;
			}
		}
		return res;
	}
};

const send = async ({ method, path, data, picture }) => {
	const opts = { method, headers: {} };
	if (picture) {
		opts.body = data;
	} else if (data) {
		opts.headers['Content-Type'] = 'application/json';
		opts.body = JSON.stringify(data);
	}

	try {
		const res = await timeoutPromise(fetch(API_BASE + path, opts));
		if (!res.ok) {
			// client or server errors
			throw res;
		}
		const json = await res.json();
		const error = json.error;
		if (error) {
			// user errors
			handleErrorJson(error);
			throw error;
		}
		const isLoggedIn =
			('isPersonalLoggedIn' in json && json.isPersonalLoggedIn) ||
			('isManagedLoggedIn' in json && json.isManagedLoggedIn);
		if (isLoggedIn) {
			countdown.start();
		} else {
			countdown.clear();
		}
		return json;
	} catch (err) {
		// user error
		// skip showing notification -> pass control back down
		if (err.message) {
			throw err;
		} else if (err instanceof TypeError) {
			notification.show(readTranslatedKey('Network error. Please try again.'), 'error');
		} else if (err.status === 408) {
			notification.show(readTranslatedKey('Request timed out. Please try again.'), 'error');
		} else {
			// all other client / server errors
			notification.show(
				readTranslatedKey('Something went wrong. Please try again later.'),
				'error'
			);
		}
		throw err;
	}

	// tbd
};

const get = (path) => {
	return send({ method: 'GET', path });
};

const del = (path, data) => {
	return send({ method: 'DELETE', path, data });
};

const post = (path, data, picture) => {
	return send({ method: 'POST', path, data, picture });
};

const put = (path, data) => {
	return send({ method: 'PUT', path, data });
};

export { fetchWithTimeout, fetchWithRetry, get, del, post, put };
